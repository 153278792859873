import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [{
		path: '/',
		redirect: '/dashboard'
	},
	{
		path: '/',
		name: 'home',
		component: Home,
		children: [{
				path: '/dashboard',
				component: () => import('../views/Dashboard.vue'),
			},
			{
				path: '/DoctorManagement',
				name: 'DoctorManagement',
				component: () => import('../views/DoctorManagement/index.vue'),
				meta: {
					title: '医生管理'
				}
			},
			{
				path: '/DoctorsPatient',
				name: 'DoctorsPatient',
				component: () => import('../views/DoctorManagement/DoctorsPatient.vue')
			},
			{
				path: '/PatientDetails',
				name: 'PatientDetails',
				component: () => import('../views/DoctorManagement/PatientDetails.vue')
			},
			{
				path: '/ReviewRecords',
				name: 'ReviewRecords',
				component: () => import('../views/DoctorManagement/ReviewRecords.vue')
			},
			{
				path: '/PatientManagement',
				name: 'PatientManagement',
				component: () => import('../views/PatientManagement/index.vue'),
				meta: {
					title: '患者管理'
				}
			},
			{
				path: '/HospitalManagement',
				name: 'HospitalManagement',
				component: () => import('../views/HospitalManagement/index.vue'),
				meta: {
					title: '医院管理'
				}
			},{
				path: '/DepartmentManagement',
				name: 'DepartmentManagement',
				component: () => import('../views/DepartmentManagement/index.vue'),
				meta: {
					title: '人员管理'
				}
			},
			{
				path: '/PersonnelManagement',
				name: 'PersonnelManagement',
				component: () => import('../views/PersonnelManagement/index.vue'),
				meta: {
					title: '科室管理'
				}
			},
			{
				path: '/FactoryManagement',
				name: 'FactoryManagement',
				component: () => import('../views/FactoryManagement/index.vue'),
				meta: {
					title: '厂家管理'
				}
			},
			{
				path: '/ElectrodeNameManagement',
				name: 'ElectrodeNameManagement',
				component: () => import('../views/ElectrodeNameManagement/index.vue'),
				meta: {
					title: '电极名称管理'
				}
			},
			{
				path: '/ElectrodeModelManagement',
				name: 'ElectrodeModelManagement',
				component: () => import('../views/ElectrodeModelManagement/index.vue'),
				meta: {
					title: '电极型号管理'
				}
			},
			{
				path: '/ProductManagement',
				name: 'ProductManagement',
				component: () => import('../views/ProductManagement/index.vue'),
				meta: {
					title: '产品管理'
				}
			},
			{
				path: '/FileManagement',
				name: 'FileManagement',
				component: () => import('../views/FileManagement/index.vue'),
				meta: {
					title: '文件管理'
				}
			},
			{
				path: '/RoleManagement',
				name: 'RoleManagement',
				component: () => import('../views/RoleManagement/index.vue'),
			},
			{
				path: '/Authorization',
				name: 'Authorization',
				component: () => import('../views/RoleManagement/Authorization.vue')
			},
			{
				path: '/AuthorizationDetail',
				name: 'AuthorizationDetail',
				component: () => import('../views/RoleManagement/AuthorizationDetail.vue')
			},
			{
				path: '/newIndex',
				name: 'newIndex',
				component: () => import('../views/PatientManagement/newIndex.vue')
			},

// 			{
// 				path: '/MenuManage',
// 				name: 'MenuManage',
// 				component: () => import('../views/MenuManage/index.vue'),
// 				meta: {
// 					title: '菜单管理'
// 				}
// 			},
			{
				path: '/them',
				name: 'them',
				component: () => import('../views/them/index.vue'),
			},
			{
				path: '/NewAddPatient',
				name: 'NewAddPatient',
				component: () => import('../views/PatientManagement/NewAddPatient.vue'),
			},
			{
				path: '/EditorPatien',
				name: 'EditorPatien',
				component: () => import('../views/PatientManagement/EditorPatien.vue'),
			},
			{
				path: '/CheckRecord',
				name: 'CheckRecord',
				component: () => import('../views/PatientManagement/CheckRecord.vue'),
			},
			{
				path: '/CheckRecordNew',
				name: 'CheckRecordNew',
				component: () => import('../views/PatientManagement/CheckRecordNew.vue'),
			},
			{
				path: '/DataQuery',
				name: 'DataQuery',
				component: () => import('../views/DataQuery/index.vue'),
				meta: {
					title: '数据查询'
				}
			},
			{
				path: '/ProductManagement',
				name: 'ProductManagement',
				component: () => import('../views/ProductManagement/index.vue'),
				meta: {
					title: '产品管理'
				}
			}
		]
	},
	{
		path: '/Login',
		name: 'Login',
		component: () => import('../views/Login.vue'),
		meta: {
			title: '登录'
		}
	},
	{
		path: '/Regist',
		name: 'Regist',
		component: () => import('../views/Regist.vue'),
		meta: {
			title: '注册'
		}
	},
	{
		path: '/503',
		name: '503',
		component: () => import('../views/503.vue'),
		meta: {
			title: '访问出错'
		}
	}
]

const router = new VueRouter({
	// history
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
