<template>
  <div id="app">
    <router-view/>
    <div class="footer_bottom">
			<div>Copyright © 2006-2022  |
			<a style="color:#333333;" href="https://beian.miit.gov.cn/" target="_blank">沪ICP备2021005410号-1</a>
			</div>
		</div>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
	html, body{
	  width:100%;
	  height: 100%;
	}
  .footer_bottom {
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: #333333;
  }
</style>
