const TEST_API={
	//选择框
	//查询医院科室医生级联
	CurrencyAll:{
		method:'get',
		url:'/currency/queryAll'
	},
	//省市区
	Provinces:{
		method:'get',
		url:'/sysExecutive/load_ExecutiveTreeList'
	},
	//医院
	Hospital:{
		method:'get',
		url:'/hospitalInfo/queryByCurrencyVo'
	},
	//医院科室
	ByHospitalDepartment:{
		method:'get',
		url:'/hospitalInfo/queryByHospitalCurrencyVo'
	},
	//科室类型
	DepartmentType:{
		method:'get',
		url:'/sysDict/queryBy'
	},
	//产品厂商
	ManufacturerModel:{
		method:'get',
		url:'/manufacturerInfoBasic/queryByManufacturerInfoCurrencyVo'
	},
	//厂商型号
	ManufacturerMarking:{
		method:'get',
		url:'/manufacturerMarking/queryByManufacturerInfoCurrencyVo'
	},
    //术者
	// UserCurrency:{
	// 	method:'get',
	// 	url:'/userInfoBasic/queryByCurrencyVo'
	// },
	UserCurrency:{
		method:'get',
		url:'/currency/queryInfo'
	},
	// 厂家下拉
	ManufactureSelect:{
		method:'get',
		url:'/manufacturerInfoBasic/currencyVo'
	},
	//角色下拉
	RoleSelect:{
		method:'get',
		url:'/sys-role/querySelect'
	},
	//授权按钮
	// RoleMenuButtonAuth:{
	// 	method:'get',
	// 	url:'/roleMenuButtonRelation/auth'
	// },
	// 公共接口
	 //获取登录验证码
	 LogiAuthenti:{
		method:'get',
		url:'/userInfoBasic/captchaBy'
	},
	 //登录
	Login:{
		method:'get',
		url:'/userInfoBasic/login'
	},
	 //重置密码
	Resetpwd:{
		method:'put',
		url:'/userInfoBasic/updateBy'
	},
	//获取菜单
	AuthMenu:{
		method:'get',
		url:'/sys-menu/auth'
	},
	//关系下拉
	UserAuth:{
		method:'get',
		url:'/userInfoBasic/auth'
	},
	//用户是否可以访问菜单
	UserCheckAuth:{
		method:'get',
		url:'/roleMenuButtonRelation/auth'
	},
	//文件管理
	//查询文件信息接口
	InspectionPage:{
		method:'get',
		url:'/inspection/queryPage'
	},
	//上传文件
	InspectionUploadFile:{
		method:'post',
		url:'/inspection/uploadFile'
	},
	//获取文件内容接口
	InspectionCurrency:{
		method:'get',
		url:'/inspection/getFileInfo'
	},
	//权限管理
	//角色分页
	RoleQueryPage:{
		method:'get',
		url:'/sys-role/queryPage'
	},
	//获取角色授权穿梭框
	RoleMenuRelation:{
		method:'get',
		url:'/role-menu-relation/query'
	},
	//修改角色授权
	RoleMenuRelationUpdate:{
		method:'put',
		url:'/role-menu-relation/update'
	},
	//新增角色
	RoleInsertByInfo:{
		method:'post',
		url:'/sys-role/insertBy'
	},
	//修改角色
	RoleUpdate:{
		method:'put',
		url:'/sys-role/update'
	},
	//删除角色
	RoleChange:{
		method:'put',
		url:'/sys-role/change'
	},
	//删除菜单授权
	RoleMenuDelete:{
		method:'put',
		url:'/role-menu-relation/delete'
	},
	//获取按钮数据权限
	RoleMenuButtonQuery:{
		method:'get',
		url:'/roleMenuButtonRelation/query'
	},
	//修改按钮授权
	RoleMenuButtonUpdate:{
		method:'put',
		url:'/roleMenuButtonRelation/update'
	},
	//获取数据权限
	rmdfrQuery:{
		method:'get',
		url:'/rmdfr/queryDataAuth'
	},
	//数据字段授权
	rmdfrDataQuery:{
		method:'get',
		url:'/rmdfr/queryDataValue'
	},
	//修改字段权限
	rmdfrDataUpdateQuery:{
		method:'put',
		url:'/rmdfr/updateDataValue'
	},
	//病患列表
	// 分页接口
	PatientsQueryPageInfo:{
		method:'get',
		url:'/patientsInfo/queryPageBy'
	},
	//查看病患信息
	PatientsQueryPageInfoBasic:{
		method:'get',
		url:'/patientsInfoBasic/queryByPatientsInfoBasic'
	},
	//新增病患信息
	PatientsInsertByInfo:{
		method:'post',
		url:'/patientsInfoBasic/insertBy'
	},
	//新增上传PDF
	PatientsinspectionReport:{
		method:'post',
		url:'/patientsInfoBasic/inspectionReport'
	},
	//新增上传PDF
	NewPatientsinspectionReport:{
		method:'post',
		url:'/patientsInfoBasic/patientsInsertDtoFileParam'
	},
	
	//查询随访记录分页
	PatientsFollowQueryPage:{
		method:'get',
		url:'/patientsFollow/queryPageBy'
	},
	//查询随访信息
	PatientsFollowInfoBasic:{
		method:'get',
		url:'/patientsInfoBasic/queryByPatientsFollowInfoBasic'
	},
	//新增随访信息
	PatientsInfoFollowInsert:{
		method:'post',
		url:'/patientsInfoBasic/insertByPatientsInfoFollowInsertDto'
	},
	// 医院列表
	// 分页接口
	HospitalQueryPageInfo:{
		method:'get',
		url:'/hospitalInfo/queryPageBy'
	},
	// 新增接口
	HospitalInsertByInfo:{
		method:'post',
		url:'/hospitalInfo/insertBy'
	},
	//修改接口
	HospitalUpdateByInfo:{
		method:'put',
		url:'/hospitalInfo/updateBy'
	},
	//科室列表
	//分页接口
	DepartmentsQueryPageInfo:{
		method:'get',
		url:'/departmentsInfo/queryPageBy'
	},
	//新增接口
	DepartmentsInsertByInfo:{
		method:'post',
		url:'/departmentsInfo/insertBy'
	},
	//修改接口
	DepartmentsUpdateByInfo:{
		method:'put',
		url:'/departmentsInfo/updateBy'
	},
	//人员列表
	//分页接口
	UserQueryPageInfo:{
		method:'get',
		url:'/userOrgRelation/queryPageBy'
	},
	//新增接口
	UserInsertByInfo:{
		method:'post',
		url:'/userInfoBasic/insertBy'
	},
	//修改接口
	UserUpdateByInfo:{
		method:'put',
		url:'/userInfoBasic/updateBy'
	},
	OrgUserRelationUpdateByInfos:{
		method:'put',
		url:'/orgUserRelation/updateBys'
	},
	//修改首选项
	OrgUserRelationUpdateByInfo:{
		method:'put',
		url:'/orgUserRelation/updateBy'
	},
	//厂家列表
	//分页接口
	ManufacturerQueryPageInfo:{
		method:'get',
		url:'/manufacturerInfoBasic/queryPageBy'
	},
	//新增接口
	ManufacturerInsertByInfo:{
		method:'post',
		url:'/manufacturerInfoBasic/insertBy'
	},
	//修改接口
	ManufacturerUpdateByInfo:{
		method:'put',
		url:'/manufacturerInfoBasic/updateBy'
	},
	//旧接口
	//产品列表
	//分页接口
	ProductQueryPageInfo:{
		method:'get',
		url:'/manufacturerProductInfo/queryPageBy'
	},
	//新增接口
	ProductInsertByInfo:{
		method:'post',
		url:'/manufacturerProductInfo/insertBy'
	},
	//修改接口
	ProductUpdateByInfo:{
		method:'put',
		url:'/manufacturerProductInfo/updateBy'
	},
	//删除接口
	ProductdeleteByInfo:{
		method:'delete',
		url:'/manufacturerProductInfo/deleteBy'
	},
	//新接口
	//产品列表
	//分页接口
	ProductQueryPage:{
		method:'get',
		url:'/product/find_page'
	},
	//新增接口
	ProductInsert:{
		method:'post',
		url:'/product/insert'
	},
	//修改接口
	ProductUpdate:{
		method:'put',
		url:'/product/update'
	},
	//删除接口
	Productdeleteo:{
		method:'delete',
		url:'/manufacturerProductInfo/deleteBy'
	},
	//电极名称列表
	//分页接口
	ElectrodeNameQueryPage:{
		method:'get',
		url:'/electrode_name/find_page'
	},
	//新增接口
	ElectrodeNameInsert:{
		method:'post',
		url:'/electrode_name/insert'
	},
	//修改接口
	ElectrodeNameUpdate:{
		method:'put',
		url:'/electrode_name/update'
	},
	//电极型号列表
	//分页接口
	ElectrodeModelQueryPage:{
		method:'get',
		url:'/electrode_model/find_page'
	},
	//新增接口
	ElectrodeModelInsert:{
		method:'post',
		url:'/electrode_model/insert'
	},
	//修改接口
	ElectrodeModelUpdate:{
		method:'put',
		url:'/electrode_model/update'
	},
	//下拉列表
	Select:{
		method:'get',
		url:'/select/list'
	},
	//导出信息
	ExportList:{
		method:'export',
		url:'/patientsInfo/queryPageByPatientsInfoList'
	}
}

export default TEST_API
