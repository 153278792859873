<template>
	<div class="header" ref="kaka">
	<!-- 	<div slcss="header-logo" style="float: left;line-height: 70px;width: 250px;background: #002140!important;">
			<el-select @change="changeValue" v-model="authToken" placeholder="请选择">
				<el-option
				v-for="item in UserAuthData"
				:key="item.authToken"
				:label="item.fullName"
				:value="item.authToken">
				</el-option>
			</el-select>
		</div> -->
		<!-- 折叠按钮 -->
		<div class="collapse-btn" @click="collapseChage" >
		    <i class="el-icon-s-operation" style="color: #5D5D5D;"></i>
		</div>
		
		<div class="header-right">
			<div class="header-user-con">
				<div class="user-info">
					<span>{{fullName}}，你好，欢迎回来</span>
					<span style="padding: 0 20px;"></span>
					<span @click="logout" class="cursors">退出系统</span>
				</div>
			</div>
		</div>
	</div>
</template>
</template>
<script>
	import bus from './bus';
	export default {
		data() {
			return {
				UserAuthData:[],//关系下拉
				authToken: '',//关系下拉值
				haha: {
					background: "#00FF00"
				},
				collapse:true,
				fullscreen: false,
				name: '',
				message: 2,
				userInfo: {},
				fullName:"",
			}
		},
		methods: {
			//下拉选择
			changeValue(val){
				this.authToken=val;
				this.UserAuthData.map((item)=>{
					if(item.authToken == this.authToken){
                        this.$store.commit('Types', item.type);
					}
				});
				if(this.authToken){
					//localStorage.setItem("authToken",this.authToken);
					this.$store.commit('AuthToken', this.authToken);
					bus.$emit('AuthorityFlag', 2);
					if(this.$route.path!=="/dashboard"){
						this.$router.push({
							path: '/'
						})
					}			
				}
			},
			//关系下拉
			async QueryUserAuth() {
				let res = await this.$Http.UserAuth({});
				if (res.code == '200') {
					this.UserAuthData=res.data;
					res.data.map((item,index)=>{
						if(item.flag){
						   this.authToken=item.authToken;
						   this.$store.commit('AuthToken', this.authToken);
						   this.$store.commit('Types', item.type);
						   bus.$emit('AuthorityFlag', 2);
						}else{
							this.authToken=res.data[0].authToken;
							this.$store.commit('AuthToken', this.authToken);
							this.$store.commit('Types',res.data[0].type);
							bus.$emit('AuthorityFlag', 2);
						}
					})
				} else {
				}
			},
			logout() {
				this.logout()
			},
			// 侧边栏折叠
			collapseChage() {
				this.collapse = !this.collapse;
				bus.$emit('collapse', this.collapse);
			},
			logout() {
				localStorage.removeItem('authToken');
				localStorage.removeItem('fullName');
				localStorage.removeItem('type');
				this.$router.push({
					path: '/Login'
				})
			}
		},
		watch: {
			//更换主题颜色需要的代码 现在不需要
			'$store.state.themecolor': function(newV, oldV) {
				//你需要执行的代码
				this.$nextTick(() => {
					this.$refs.kaka.style.backgroundColor = `#${newV}`
                })
            }
		},
		mounted() {
			// this.$refs.kaka.style.background = `#002140`;
			this.fullName=localStorage.getItem("fullName");
			if (document.body.clientWidth < 1500) {
				this.collapseChage();
			}
			//关系下拉
			// this.QueryUserAuth();
		},
	}
</script>
<style lang="scss">
	.header {
		.el-dropdown-link {
			color: #ffffff !important;
		}
	}
</style>
<style scoped lang="scss">
	.header {
		position: relative;
		box-sizing: border-box;
		width: 100%;
		height: 70px;
		font-size: 22px;
		color: #ffffff;
    border-bottom: 1px solid #eee;
	}

	.collapse-btn {
		height: 70px;
		float: left;
		padding: 0 30px;
		cursor: pointer;
		line-height: 70px;

		img {
			padding-right: 30px;
		}

		.header-logo {
			span {
				font-weight: 700;
				font-style: normal;
				font-size: 39px;
				color: #FFFFFF;

			}
		}
	}

	.header-right {
		float: right;
		padding-right: 50px;
	}

	.header-user-con {
		display: flex;
		height: 70px;
		align-items: center;
	}

	.user-info {
		span {
			// font-weight: 655;
			font-style: normal;
			font-size: 18px;
			color: #5D5D5D;
		}
	}
</style>
