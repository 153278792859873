import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state:{
        themecolor:'20a0ff',//默认为20a0ff
		authToken:"",
		fullName:"",
		types:"",
		menuList: [],
		tableCol: [],
		outerbtns: [],
		btns: []
    },
    mutations:{
        //更新主题颜色
		setThemeColor(state,curcolor){
            this.state.themecolor = curcolor;
		},
		AuthToken(state,authToken){
            this.state.authToken = authToken;
			localStorage.setItem("authToken",authToken);
        },
		FullName(state,fullName){
            this.state.fullName = fullName;
			localStorage.setItem("fullName",fullName);
		},
		Types(state,types){
            this.state.types = types;
			localStorage.setItem("type",types);
		},
		SET_MENU: (state, menuList) => {
		  state.menuList = menuList
		},
		SET_TABLECOL: (state, tableCol) => {
		  state.tableCol = tableCol
		},
		SET_OUTERBTNS: (state, outerbtns) => {
		  state.outerbtns = outerbtns
		},
		SET_BTNS: (state, btns) => {
		  state.btns = btns
		}
    },
	actions:{
		SET_MENU: (context, menuList) => {
		  context.commit("SET_MENU", menuList)
		},
		SET_TABLECOL: (context, tableCol) => {
		  context.commit("SET_TABLECOL", tableCol)
		},
		SET_OUTERBTNS: (context, outerbtns) => {
		  context.commit("SET_OUTERBTNS", outerbtns)
		},
		SET_BTNS: (context, btns) => {
		  context.commit("SET_BTNS", btns)
		}
	}
});
export default store;
