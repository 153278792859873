import axios from 'axios'; //引用axios
import Service from './api'
import router from '../router'
import {
	Loading
} from 'element-ui'
//http://192.168.3.20:10080 cui
//http://101.133.239.239:10000 测试
//http://192.168.100.77:10080 pang
//http://www.5g-followup.com:10011 http://101.132.33.170:10011生产
//创建实例
let instance = axios.create({
	baseURL: 'http://106.14.175.189:10011'//生产
	// baseURL: 'http://192.168.3.28:10010'
	// baseURL: 'http://106.15.138.155:10000'//测试
})
//包裹请求方法的容器
const Http = {};
//请求格式和参数的统一
for (let key in Service) {
	let api = Service[key]; //url method

	Http[key] = async function(params, isFormData = false, config = {
		headers: {
			'authToken': localStorage.getItem("authToken"),
		},
	}) {
		//[async 作用：避免进入回调地狱 ]
		//[params：请求参数为get和delete时放在url上、put post patch时（data）]
		//[isFormData:标识是否是form-data请求]
		//[config:配置参数]
		let url = api.url
		let newParams = {}

		if (params && isFormData) {
			newParams = new FormData()
			Object.keys(params).forEach(function(key) {
				newParams.append(key, params[key])
			})
		} else {
			newParams = params
		}
		let response = {}; //请求返回值

		if (api.method === 'put' || api.method === 'post' || api.method === 'patch') {
			try {
				response = await instance[api.method](api.url, newParams, config)
			} catch (err) {
				response = err
			}
		} else if (api.method === 'delete' || api.method === 'get') {
			config.params = newParams
			try {
				response = await instance[api.method](api.url, config)
			} catch (err) {
				response = err
			}
		}else if(api.method === 'export'){
			config.params = newParams
			config.responseType = "blob"
			try {
				response = await instance['get'](api.url, config)
			} catch (err) {
				response = err
			}
		}
		return response
	}
}

//拦截器的添加
let loading;
const loadingShow = function() {
	loading = Loading.service({
		lock: true,
		text: '加载中',
		spinner: 'el-icon-loading',
		background: 'rgba(0, 0, 0, 0.7)'
	});
};
const loadingClose = function() {
	loading.close();
};
//请求拦截器
instance.interceptors.request.use(
	config => {
		loadingShow();
		return config
	}, () => {
		loadingClose();
	}
)
//响应拦截器
instance.interceptors.response.use(
	res => {
		//http拦截器
		loadingClose();
		if(res.data.code == "3004" || res.data.code=="3005" || res.data.code=="3010" || res.data.code=="3011"){
			router.push({
			  path: '/Login'
			})
		}
		return res.data;
	}, () => {
		loadingClose();
	}
)

export default Http
