<template>
	<div class="wrapper">
		<el-container>
			<v-sidebar></v-sidebar>
		  <el-container class="wrapper_right">
			<v-header></v-header>
				<div class="content-box" :class="{'content-collapse':collapse}">
					<!-- <v-tags></v-tags> -->
					<div class="content" ref="content_height">
						<transition name="move" mode="out-in">
							<keep-alive :include="tagsList">
								<router-view></router-view>
							</keep-alive>
						</transition>
					</div>
				</div>
		  </el-container>
		</el-container>
	</div>
</template>

<script>
	import bus from '@/components/bus';
	import vHeader from '@/components/Header.vue' //头部组件
	import vSidebar from '@/components/Sidebar.vue' //侧边栏组件
	import vTags from '@/components/Tags.vue' //标签组件
	export default {
		name: 'home',
		data() {
			return {
				tagsList: [],
				collapse: false,
				menuid: ''
			}
		},
		components: {
			vHeader,
			vSidebar,
			vTags
		},
		created() {
			bus.$on('collapse', msg => {
				this.collapse = msg;
			})
			// 只有在标签页列表里的页面才使用keep-alive，即关闭标签之后就不保存到内存中了。
			bus.$on('tags', msg => {
				let arr = [];
				for (let i = 0, len = msg.length; i < len; i++) {
					msg[i].name && arr.push(msg[i].name);
				}
				this.tagsList = arr;

			})
			window.fullHeight = document.documentElement.clientHeight-70;
			console.log(window.fullHeight,"1")
		},
		methods: {
			
		},
		mounted() {
			var that = this;
			window.onresize = () => {
			   return (() => {
			        window.fullHeight = document.documentElement.clientHeight-70;
			        that.$refs.content_height.style.height = window.fullHeight +'px';
					console.log(window.fullHeight,"2")
			    })()   
			}    
		}
	}
</script>
<style>
	.wrapper {
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
	.wrapper_right{
		height: 100vh;
		overflow: hidden;
	}
  .content-box {
		position: absolute;
		left: 250px;
		right: 0;
		top: 70px;
		bottom: 0;
		overflow-y: scroll;
		-webkit-transition: left .3s ease-in-out;
		transition: left .3s ease-in-out;
		background: #F0F2F5;
  }
  .content {
		width: auto;
		/* padding: 10px; */
	}

	.content-collapse {
		left: 65px;
	}
</style>
