<template>
	<div>
		
	
	<!-- unique-opened -->
	<div class="sidebar">
		<div :class="{ 'hide_sty': collapse }" clcss="header-logo" style="line-height: 70px;width: 250px;background: #002140!important;display: flex;align-items: center;justify-content: center;">
			<el-select  @change="changeValue" v-model="authToken" placeholder="请选择">
				<el-option
				v-for="item in UserAuthData"
				:key="item.authToken"
				:label="item.fullName"
				:value="item.authToken">
				</el-option>
			</el-select>
		</div>
		<el-menu :default-openeds='openeds' class="sidebar-el-menu" :default-active="onRoutes" :collapse="collapse" background-color="#2f4050"  text-color="#fff"  router>
			
			<template v-for="item in items" >
				<template v-if="item.children">
					<el-submenu :index="item.code" :key="item.code">
						<template slot="title">
							<i :class="item.no"></i><span slot="title">{{ item.label }}</span>
						</template>
						<template v-for="subItem in item.children">
							<template v-if="subItem.children">
								
								<el-submenu :index="subItem.code" :key="subItem.code">
									<template slot="title">
										<span slot="title">{{ subItem.label }}-{{subItem.code}}</span>
									</template>
									<el-menu-item v-for="(subItemSen,idx) in subItem.children" :key="idx" :index="subItemSen.code">
										{{ subItemSen.label }}
									</el-menu-item>
								</el-submenu>
							</template>
							<template v-else>
								<el-menu-item :index="subItem.code" :key="subItem.code">
									<i :class="subItem.no"></i><span slot="title">{{ subItem.label }}</span>
								</el-menu-item>
							</template>
						</template>
					</el-submenu>
				</template>
				<template v-else>
					<el-menu-item :index="item.code" :key="item.code">
						<i :class="item.no"></i><span slot="title">{{ item.label }}</span>
					</el-menu-item>
				</template>
			</template>
		</el-menu>
	</div>
	</div>
	
</template>
<script>
import bus from './bus';
export default {
	data() {
		return {
			UserAuthData:[],//关系下拉
			authToken: '',//关系下拉值
			collapse: false,
            items: [],
            openeds:[],
			authrList: [
				'PatientManagement','FileManagement',
			]
        }
	},
	computed: {
		onRoutes() {
			return this.$route.path.replace('/', '');
		},
	},
	created() {
		bus.$on('collapse', msg => {
			this.collapse = msg;
		})
		bus.$on('AuthorityFlag', msg => {
			this.openeds=[];
            this.QueryAuthMenu(msg);
       })
    },
    watch: {
        $route(to){
            //是否可以访问菜单
//             if(!(JSON.stringify(to.meta) == "{}")){
//                this.QueryUserCheckAuth(to.path);
//             }
			if(this.authrList.includes(this.$route.name)){
				this.QueryUserCheckAuth(this.menuList[this.$route.name])
			}
        },
    },
	methods: {
        //是否可以访问该菜单
        // async QueryUserCheckAuth(toPath) {
        //     let menuUrl=toPath.replace(/[/]/g,"");
        //     let info = {
        //         menuUrl:menuUrl
        //     }
		// 	let res = await this.$Http.UserCheckAuth(info);
		// 	if (res.code == '200') {
        //         if(!res.data){
        //             if(menuUrl!=="dashboard"){
        //                this.$router.push('/')
        //             }
        //         }
		// 	} else {
		// 		this.$message.error(res.message);
		// 	}
		// },
		//按钮权限
		async QueryUserCheckAuth(menuCode) {
            let info = {
                menuId:menuCode
            }
			let res = await this.$Http.UserCheckAuth(info);
			if (res.code == '200') {
                let btnListPage = []
                let btnListLine = []
                if(res.data.buttonAuth){
                	res.data.buttonAuth.map((item)=>{
                		if(item.type == 1){
                			item.codeList.map((val)=>{
                				btnListPage.push(val.no)
                			})
                		}
                		if(item.type == 2){
                			item.codeList.map((val)=>{
                				btnListLine.push(val.no)
                			})
                		}
                	})
                }
                this.$store.dispatch('SET_OUTERBTNS', btnListPage)
                this.$store.dispatch('SET_BTNS', btnListLine)
			} else {
				this.$store.dispatch('SET_OUTERBTNS', [])
				this.$store.dispatch('SET_BTNS', [])
			}
		},
		//菜单
		async QueryAuthMenu(msg) {
			let info = {}
			let res = await this.$Http.AuthMenu(info);
			if(res.code == '200') {
				// this.$store.dispatch('SET_MENU', res.data)
                this.items=res.data;
				this.menuList = {};
				let openeds=[]
				res.data.map((val)=>{
					openeds.push(val.code)
					this.openeds=openeds;
					if(val.children){
						val.children.map((item)=>{
							this.menuList[item.code] = item.id
						})
					}
				})
				localStorage.setItem('menuList', JSON.stringify(this.menuList));
				this.QueryUserCheckAuth(this.menuList[this.$route.name]);
//                 if(msg==2){
//                     this.openeds=[]
//                 }
			}else{
				this.$store.dispatch('SET_MENU', [])
				localStorage.setItem('menuList', [])
			}
		},
		//下拉选择
		changeValue(val){
			this.authToken=val;
			this.UserAuthData.map((item)=>{
				if(item.authToken == this.authToken){
		                  this.$store.commit('Types', item.type);
				}
			});
			if(this.authToken){
				//localStorage.setItem("authToken",this.authToken);
				this.$store.commit('AuthToken', this.authToken);
				bus.$emit('AuthorityFlag', 2);
				if(this.$route.path!=="/dashboard"){
					this.$router.push({
						path: '/'
					})
				}			
			}
		},
		//关系下拉
		async QueryUserAuth() {
			let res = await this.$Http.UserAuth({});
			if (res.code == '200') {
				this.UserAuthData=res.data;
				res.data.map((item,index)=>{
					if(item.flag){
					   this.authToken=item.authToken;
					   this.$store.commit('AuthToken', this.authToken);
					   this.$store.commit('Types', item.type);
					   bus.$emit('AuthorityFlag', 2);
					}else{
						this.authToken=res.data[0].authToken;
						this.$store.commit('AuthToken', this.authToken);
						this.$store.commit('Types',res.data[0].type);
						bus.$emit('AuthorityFlag', 2);
					}
				})
			} else {
			}
		},
	},
	mounted() {
		//菜单
        this.QueryAuthMenu();
        // let paths=this.$route.path;
        // this.QueryUserCheckAuth(paths);
		//关系下拉
		this.QueryUserAuth();
        
    },
}
</script>
<style scoped>
	.hide_sty{
		display: none!important;
	}
	.no_hide_sty{
		display: block;
	}
	/deep/ .el-input__inner{
      background: transparent!important;
	  border: 0;
	  color: #fff;
    }
	.el-menu-item.is-active {
		background-color: #20a0ff!important;
		color: #FFFFFF;
	}
	
	.sidebar {
		/* display: block;
		position: absolute;
		left: 0;
		top: 70px;
		bottom: 0; */
		/* overflow-y: scroll; */
		height: 100vh;
	}
	
	.sidebar::-webkit-scrollbar {
		width: 0;
	}
	
	.sidebar-el-menu:not(.el-menu--collapse) {
		width: 250px;
	}
	
	.sidebar>ul {
		height: 100%;
	}
</style>