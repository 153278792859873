import Vue from 'vue'
import App from './App.vue'
import router from './router'

import ElementUI, { Message } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import "./assets/common/public.scss"
import '../theme/index.css'
import store from './vuex/store.js';
// 引入皮肤
import './assets/css/theme/00FF00/index.css';
import './assets/css/theme/02abfd/index.css';
import './assets/css/theme/a25fbc/index.css';
import './assets/css/theme/00BCD4/index.css';
// 引入字体自适应插件
import 'lib-flexible'
// 引入接口api
import Http from '@/api/http'
Vue.prototype.$Http = Http;

Vue.config.productionTip = false
Vue.use(ElementUI)
//使用钩子函数对路由进行权限跳转
const whitePath = ['/Login'];
router.beforeEach((to, from , next)=>{
	let futureToken = localStorage.getItem("authToken");
	if(futureToken){
		next()
	}else{
		if(!whitePath.includes(to.path)){
			next('/Login')
		}else{
			next()
		}
	}
})
new Vue({
	store,
	router,
  render: h => h(App),
}).$mount('#app')
